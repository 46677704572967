<template>
  <div>
    <page-heading
      heading="Настройки"
    >
      <span slot="help"></span>
      <span slot="right"></span>
    </page-heading>

    <el-card
      v-if="$canAndRulesPass(user, $permissions.SETTING.UPDATE)"
    >
      <component
        :is="settingTemplate"
      ></component>
    </el-card>


    <el-card
      v-if="$canAndRulesPass(user, $permissions.RBAC.LIST)"
      style="margin-top: 20px;"
    >
      <rbac-table></rbac-table>
    </el-card>




  </div>
</template>

<script>
import PageHeading from "@/components/PageHeading";
import RbacTable from "@/components/settings/rbac/RbacTable";
import {mapGetters} from "vuex";

export default {
  name: 'Settings',
  components: {RbacTable, PageHeading},

  computed: {
    ...mapGetters(['user']),
    settingTemplate() {
      return this.$companyConfiguration.settings.getTemplate();
    }
  },
  data() {
    return {

    }
  },
  mounted() {},
  methods: {

  }
}

</script>


<style scoped lang="scss">
@import "./src/assets/css/project-variables";


</style>
